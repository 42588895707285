* {
  box-sizing: border-box;
}

html,
body {
  background: black;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

h1 {
  position: absolute;
  z-index: 2;
  right: 10%;
  width: 32rem;
  text-align: left;
  bottom: 10%;
  font-family: monospace;
  color: white;
  font-size: 6rem;
}

main {
  flex: 1;
}

@media (max-width: 800px) {
  h1 {
    font-size: 3rem;
    width: 16rem;
    bottom: 10%;
    right: unset;
  }
}
